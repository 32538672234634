<template>
    <div class="password">
        <van-nav-bar
            :title="$t('LAN.change_pwd')"
            :left-text="$t('LAN.back')"
            left-arrow
            @click-left="goBack"
        />
        <!-- 绑定邮箱找回 -->
        <div class="content">
            <div class="tips">
                <p>{{ $t("LAN.change_pwd_tips") }} </p>
            </div>
            <van-form @submit="handleChange">
                <van-field
                    v-model="oldPassword"
                    type="password"
                    clearable
                    :label="$t('LAN.old_pwd')"
                    left-icon="lock"
                    :placeholder="$t('LAN.input_old_pwd')"
                    :rules="[{ required: true, message: $t('LAN.input_old_pwd') }]"
                />
                <van-field
                    v-model="newPassword"
                    type="password"
                    clearable
                    :label="$t('LAN.new_pwd')"
                    left-icon="lock"
                    :placeholder="$t('LAN.input_new_pwd')"
                    :rules="[{ required: true, message: $t('LAN.input_new_pwd') }]"
                />
                <van-field
                    v-model="confirmPassword"
                    type="password"
                    clearable
                    :label="$t('LAN.confirm_pwd')"
                    left-icon="lock"
                    :placeholder="$t('LAN.input_confirm_pwd')"
                    :rules="[{ required: true, message: $t('LAN.input_confirm_pwd') }]"
                />
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit">{{$t('LAN.change_now')}}</van-button>
                </div>
            </van-form>

        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter} from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from "vant";
import { reqestPassword } from "@/service/user"
import { useI18n } from 'vue-i18n'
export default {
    
    setup() {
        const store = useStore()
        const router = useRouter()
        const { t } = useI18n()
        const state = reactive({
            username: '',
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        })

        onMounted(() => {
            state.username = store.getters.username
        });

        const goBack = () => {
            router.go(-1)
        }

        const handleChange = async () => {
            if (state.newPassword.length < 6 || state.newPassword.length > 18) {
                Toast(t('LAN.account_length'))
                return;
            }
            if (state.newPassword != state.confirmPassword) {
                Toast(t('LAN.input_confirm_pwd'))
                return;
            }

            Toast.loading(t('LAN.sending'));
            await reqestPassword({
                oldPassword:state.oldPassword,
                password:state.newPassword,
                passwordConfirmation:state.newPassword
            });
            store.commit("setPassword", state.newPassword)
            store.commit("setLastPassword", state.newPassword)
            Toast(t('LAN.modify_successfully'))
            state.oldPassword = ''
            state.newPassword = ''
            state.confirmPassword = ''
        }

        return {
            ...toRefs(state),
            goBack,
            handleChange
        }
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.password {
    height: calc(var(--vh, 1vh) * 100);
    .content {
        margin-top: 10px;
    }
}
.tips {
    margin: 10px;
    text-align: left;
    color: #8c8c8c;
    font-size: 14px;
}
</style>